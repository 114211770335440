body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #5b7e96 !important;
  color: white !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navBarFullScreen {
  background-color: #5b7e96;
  color: white;
  /*height: 100%;*/
  /*width: 100%;*/
  /*height: 100vh;*/
  /*width: 100vh;*/

  /* position: fixed; */
}

.blueColor {
  background-color: #5b7e96 !important;
  color: white !important;
}

.blue {
  color: #5b7e96;
}

.grey {
  color: rgb(56, 55, 55);
  font-weight: 500;
}

.whiteColor {
  background-color: white;
  color: #5b7e96 !important;
}

.blue {
  color: #5b7e96 !important;
  font-size: 64px;
  font-weight: 800;
  font-family: sans-serif;
}

.title {
  display: flex;
  color: #f1ecec;
  font-size: 64px;
  font-weight: bold;
  text-align: left;
}

.text {
  color: #f1ecec !important;
  font-weight: bold;
  font-size: 1.3em;
  line-height: 1.6;
}

.container-fluid {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.rowTextHome {
  min-width: 300px;
}

@media (min-width: 1000px) {
  .rowTextHome {
    min-width: 500px;
  }
}

.navItems {
  font-size: larger;
  line-height: 20px;
  padding: 0 10px;
}

.card {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: transparent !important;
}

.navItems:hover {
  text-decoration: underline;
}

.text {
  font-family: "Lato", sans-serif;
  font-weight: 300;
  text-align: left;
}

.span {
  color: black;
  font-weight: bold;
  padding-bottom: 3rem;
  font-size: larger;

  font-weight: bolder;
}

.navbar {
  padding: 0px !important;
  max-height: 100vh !important;
}

.container {
  height: 100%;
  width: 100%;
}

.containerCircle {
  min-height: 100%;
}

.circleGreen {
  background-image: url("./assets/greenCircle.png");
  min-height: 100%;
  background-position: top right;
  background-repeat: no-repeat;
  background-size: cover;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 50px 0px 0px 0px;
}

.second {
  width: 100%;
  background-image: none;
}

.mainTitle {
  font-size: 64px;
  font-weight: bold;
  font-family: "Lato", sans-serif;
}

.secondSection {
  height: 100%;
  width: 100%;
  background-color: #9ec8c2;
  /* margin-top: 30px;
    padding: 60px; */
}

.btn-primary {
  text-transform: capitalize !important;
  color: #fff;
  border-color: #9ec8c2 !important;
  background-color: #9ec8c2 !important;
  border-radius: 20px !important;
}

.prevFooter {
  display: grid;
  height: 123px;
  justify-items: center;
  align-content: center;
  padding-top: 30px;
  background-image: url(http://www.hi-services.es/wp-content/uploads/2018/07/Group.png);
  background-position: bottom left;
  background-repeat: no-repeat;
  background-size: auto;
}

.sectionWhy {
  background-image: url(http://www.hi-services.es/wp-content/uploads/2018/07/Oval-2-Copy.png);
  background-position: center left;
  background-repeat: no-repeat;
  background-size: auto;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.reasons {
  justify-items: baseline;
  align-content: left;
  font-weight: 20px;
}

.footer {
  max-width: 100% !important;
  min-height: 96px;
  background-color: #666666;
}

.nav-link {
  color: #fff;
}

.nav {
  padding: 30px 0px;
}

.nav-link {
  font-size: smaller;
  padding: 0.5rem 0.2rem !important;
}

.copy {
  color: #fff;
  justify-content: center !important;
  flex-direction: row !important;
  text-align: center !important;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.border {
  border: black 3px solid;
}

.pink {
  border: pink 3px solid;
}

.contact {
  /* background-color: #86bbb2; */
  color: white !important;
}

.green {
  color: #86bbb2 !important;
}

.input {
  /* background-color: rgba(255,255,255,0) !important; */
  border-color: #ffffff !important;
  border-width: 3px 3px 3px 3px !important;
  border-radius: 40px 40px 40px 40px !important;
  font-size: 18px !important;
  min-height: 59px !important;
  padding: 7px 20px !important;
}

.containerPolicy {
  background-color: #eaeaea;
  text-align: left;
  border-radius: 0.4%;
}

.pContainer {
  max-width: 80.666%;
  font-size: 300;
}

.imgContainer {
  max-width: 100%;

  max-height: 100%;
}

.videoContainer {
  align-content: center;
  max-width: 80%;
  display: flex !important;
}

.smallVideo {
  padding: 0px !important;
  max-width: 50%;
  max-height: 10%;
}

.video {
  background-color: #fff;
  max-width: 90%;
  min-height: 400px;
  /* width: 80%; */
  margin: 0;
  line-height: 1;
  border: none;
}

.headerVideo {
  /* position: fixed; */
  /*right: 0;*/
  /*bottom: 0;*/
  /*width: 100%;*/
  max-width: 100%;
  /*min-height: 100%;*/
  max-height: 550px;
  /*max-width: 100%;*/
}

.red {
  object-fit: cover;
  width: 100%;
  min-height: 100%;
}

.imgW {
  max-width: 100%;
  height: 100%;

  background-position: center;
  background-image: cross-fade();
}

.contributors {
  background-color: white !important;
  padding: 20px !important;
  border-radius: 40px;
  max-width: 500px;
}

.containerImgAndTitle {
  text-align: left;
  width: 400px !important;
  padding: 40px;
  float: left;
}

.blogCard {
  width: 18rem;
  height: 25rem;
}

.banner {
  width: 80%;
  min-height: 100%;
  text-align: start;
}

.btnBanner {
  background-color: transparent !important;
  border: solid white 2px !important;
}

.socialIcons {
  background-color: #fff;
}

li {
  text-align: left !important;
  /* list-style-position: inside; */
  text-decoration: none !important;
  line-height: 1.5;
  font-size: large;
}

ul {
  text-decoration: none !important;
  /* text- */
}

/* MAPA */

.leaflet-container {
  /* width: 100vw; */
  height: 100vh;
  min-width: 100%;
  min-height: 100%;
}

.leaflet-container a.leaflet-popup-close-button {
  color: white;
}

.white {
  color: white !important;
}

.borderB {
  border-bottom: 3px solid gray !important;
}

.lookFor {
  background-color: #5b7e96;
  border: 0.5px solid white;
  justify-content: center;
  color: white;
  position: sticky !important;
  top: 101px !important;
  z-index: 1020 !important;
}

.inp {
  background-color: #5b7e96;
  border: 0.5px solid white;
  border-radius: 4px;
  color: #fff;
  /* max-width: 1px; */
}

.popup {
  color: none;
  margin: 0px 0px;
  /* position: fixed; */
  margin: 0 auto;

  position: relative !important;
  opacity: 1;
  transform: none !important;
  top: 30px !important;
  left: 70px !important;
}

.leaflet-popup-tip-container {
  display: none;
}

.leaflet-popup-content {
  box-sizing: border-box !important;
  margin: 0px !important;
  bottom: -7px !important;
  left: -557px !important;
}

.pop {
  text-transform: capitalize;
  font-weight: bolder;
  /* color: white; */
}

.img-fluid {
  border-radius: 10px;
  background-color: red !important;
  z-index: 2;
}

.leaflet-popup-content-wrapper {
  background-color: transparent !important;
  box-shadow: none !important;
}

.photoContainer {
  min-height: 320px !important;
  min-width: 300px !important;
  background-size: cover !important;
  display: flex;
  align-items: flex-end;
  color: white;
  z-index: 2;
  justify-content: space-between;
}

.info {
  width: 100%;
  color: black;
  background-color: #fff;
  opacity: 0.7;
  z-index: 1;
  position: absolute;
  z-index: 2;
}

.up {
  position: absolute;
  z-index: 3;
  font-size: x-large;
}

.titlePop {
  top: 4px;
  /* color: black !important; */
}

.blogContainer {
  min-height: 100vh !important;
}

@media (max-width: 576px) {
  .contributors {
    background-color: white !important;
    padding: 20px !important;
    border-radius: 40px;
    /* /* width:300px */
  }

  .title {
    /* background-color: rebeccapurple !important; */
    font-size: 32px;
    padding: 0px;
  }

  .pContainer {
    /* font-size: 200; */
    font-size: 0.7rem;
    /* background-color: lightcoral; */
  }

  .imgCouncil {
    height: 120px !important;
    width: 120px !important;
  }
}

@media (max-width: 900px) {
  .title {
    /* background-color: rebeccapurple !important; */
    font-size: 32px;
    padding: 0px 0px;
  }

  .pContainer {
    /* font-size: 200; */
    font-size: 1rem;
  }
}

.subTitle {
  color: white;
  font-size: 32px;
  display: flex;
  text-align: unset !important;
}

.bannerBackground {
  background-image: url("./assets/bannerImg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.backBanner {
  background-color: #5b7e96;
  opacity: 0.5;
  height: 100%;
  width: 100%;
}

.backBannerTwo {
  background-color: #41484c;
  opacity: 0.8;
  height: 100%;
  width: 100%;
}

.mainBanner {
  background-image: url("./assets/hands.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.productBanner {
  background-image: url("./assets/palmeras.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 84vh;
  width: 100%;
}

.aboutBanner {
  background-image: url("./assets/Sobre-nosotros.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-ble {
  background-color: #666666 !important;
}

.backBannerThree {
  background-color: #444647;
  opacity: 0.7;
  height: 100%;
  width: 100%;
}

.modal-dialog {
  margin-bottom: 0px;
  position: sticky;
  bottom: 0px;
  left: none !important;
  max-width: none !important;
}

.modal {
  align-items: flex-end;
  align-content: center;
  display: flex !important;
  max-width: 100% !important;
  /* display: none !important; */
  position: fixed;
  height: none !important;
  /* bottom: 0 !important; */
  /* top: none !important; */
  /* left: none !important; */
}

.btn {
  min-width: 120px;
}
